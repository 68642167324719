const alert = {
    state: {
      name: 'alerts',
      errors: []
    },
    getters: {
    },
    mutations: {
        addErrors(state,error) {
            this.errors.push(error);
        }
    },
    actions: {
        alert_error(context,{message, time}) {
            this._vm.$notify({
                message: message,
                icon: "tim-icons icon-bell-55",
                horizontalAlign: 'right',
                verticalAlign: 'top',
                type: 'danger',
                timeout: time ? time : 0
            });
        },
        alert_success(context,{message, time}) {
            this._vm.$notify({
                message: message,
                icon: "tim-icons icon-bell-55",
                horizontalAlign: 'right',
                verticalAlign: 'top',
                type: 'success',
                timeout: time ? time : 0
            });
        }
    }
}
export default alert;