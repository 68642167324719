import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";

// Admin pages
const Dashboard = () =>
    import ( /* webpackChunkName: "dashboard" */ "@/pages/Dashboard.vue");
const Notifications = () =>
    import ( /* webpackChunkName: "common" */ "@/pages/Notifications.vue");
const Icons = () =>
    import ( /* webpackChunkName: "common" */ "@/pages/Icons.vue");
const Maps = () =>
    import ( /* webpackChunkName: "common" */ "@/pages/Maps.vue");
const Typography = () =>
    import ( /* webpackChunkName: "common" */ "@/pages/Typography.vue");
const TableList = () =>
    import ( /* webpackChunkName: "common" */ "@/pages/TableList.vue");
const Login = () =>
    import ( /* webpackChunkName: "common" */ "@/pages/Login.vue");
const Profile = () =>
    import ( /* webpackChunkName: "common" */ "@/pages/Profile.vue");
const Credentials = () =>
    import ( /* webpackChunkName: "common" */ "@/pages/Credentials.vue");
const Logs = () =>
    import ( /* webpackChunkName: "common" */ "@/pages/Logs.vue");
const SendMail = () =>
    import ("@/pages/SendMail.vue")

const Auth = (to) => {
    if (!to) {
        return window.localStorage.getItem('token') && window.localStorage.getItem('token') != 'null' ? 'profile' : 'login';
    } else {
        return window.localStorage.getItem('token') && window.localStorage.getItem('token') != 'null' ? true : false;
    }
}

const routes = [{
    path: "/login",
    name: "login",
    component: Login
}, {

    path: "/",
    component: DashboardLayout,
    redirect: to => {
        return Auth(false);
    },
    children: [{
        path: "dashboard",
        name: "dashboard",
        component: Dashboard
    }, {
        path: "profile",
        name: "profile",
        component: Profile,
        beforeEnter: (to, from, next) => {
            (Auth(to)) ? next(): next("login");
        }
    }, {
        path: "credentials",
        name: "credentials",
        component: Credentials,
        beforeEnter: (to, from, next) => {
            (Auth(to)) ? next(): next("login");
        }
    }, {
        path: "logs",
        name: "logs",
        component: Logs,
        beforeEnter: (to, from, next) => {
            (Auth(to)) ? next(): next("login");
        }
    }, {
        path: "sendmail",
        name: "sendmail",
        component: SendMail,
        beforeEnter: (to, from, next) => {
            (Auth(to)) ? next(): next("login");
        }
    }, {
        path: "notifications",
        name: "notifications",
        component: Notifications,
        beforeEnter: (to, from, next) => {
            (Auth(to)) ? next(): next("login");
        }
    }, {
        path: "icons",
        name: "icons",
        component: Icons,
        beforeEnter: (to, from, next) => {
            (Auth(to)) ? next(): next("login");
        }
    }, {
        path: "maps",
        name: "maps",
        component: Maps,
        beforeEnter: (to, from, next) => {
            (Auth(to)) ? next(): next("login");
        }
    }, {
        path: "typography",
        name: "typography",
        component: Typography,
        beforeEnter: (to, from, next) => {
            (Auth(to)) ? next(): next("login");
        }
    }, {
        path: "table-list",
        name: "table-list",
        component: TableList,
        beforeEnter: (to, from, next) => {
            (Auth(to)) ? next(): next("login");
        }
    }]
}, {
    path: "*",
    component: NotFound
}, ];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;