/*
 =========================================================
 * Vue Black Dashboard - v1.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/black-dashboard
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from "vue";
import Vuex from 'vuex'
import VueRouter from "vue-router";
import PrimeVue from 'primevue/config';
import RouterPrefetch from 'vue-router-prefetch'
import App from "./App";
import Axios from 'axios'
// TIP: change to import router from "./router/starterRouter"; to start with a clean layout
import router from "./router/index";
import store from "./store/index.js";
import env from "./env.js";

import BlackDashboard from "./plugins/blackDashboard";
import i18n from "./i18n"
import './registerServiceWorker'

import "primevue/resources/themes/saga-blue/theme.css";
import 'primevue/resources/primevue.min.css'
import "primeicons/primeicons.css";

Vue.use(BlackDashboard);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
Vue.use(Vuex);
Vue.use(PrimeVue);
Vue.prototype.$http = Axios;

Vue.mixin({
    data: function() {
        return {
            API: env.API
        }
    }
})

/* eslint-disable no-new */
new Vue({
    router,
    i18n,
    render: h => h(App),
    store: store
}).$mount("#app");