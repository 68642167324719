<template>
  <footer class="footer">
    
  </footer>
</template>
<script>
  export default {
    data() {
      return {
        
      }
    }
  };
</script>
<style>
</style>
