<template>
  <div class="wrapper">
    <side-bar>
      <template slot="links">
        <sidebar-link to="/profile" :name="$t('sidebar.profile')" icon="tim-icons icon-single-02"/>
        <sidebar-link to="/credentials" :name="$t('Credentials and Apis')" icon="tim-icons icon-single-02"/>
        <sidebar-link to="/logs" :name="$t('sidebar.logs')" icon="tim-icons icon-single-02"/>
        <sidebar-link to="/sendmail" name="Send mail" icon="tim-icons icon-single-02"/>

        <!-- <sidebar-link to="/dashboard" :name="$t('sidebar.dashboard')" icon="tim-icons icon-chart-pie-36"/>
        <sidebar-link to="/icons" :name="$t('sidebar.icons')" icon="tim-icons icon-atom"/>
        <sidebar-link to="/maps" :name="$t('sidebar.maps')" icon="tim-icons icon-pin"/>
        <sidebar-link to="/notifications" :name="$t('sidebar.notifications')" icon="tim-icons icon-bell-55"/>
        <sidebar-link to="/profile" :name="$t('sidebar.userProfile')" icon="tim-icons icon-single-02"/>
        <sidebar-link to="/table-list" :name="$t('sidebar.tableList')" icon="tim-icons icon-puzzle-10"/>
        <sidebar-link to="/typography" :name="$t('sidebar.typography')" icon="tim-icons icon-align-center"/>
        <sidebar-link to="/dashboard?enableRTL=true" :name="$t('sidebar.rtlSupport')" icon="tim-icons icon-world"/> -->
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content>

      </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">
</style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
import {UsersServices} from "../../services/UsersServices";
export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    }
  },
  created() {
    this.Session();
    UsersServices.single(this.$store.state.Session.user_id).then((response)=>{
      const result = response.data;
      if(result.result == "success"){
        this.$store.commit("saveUser",result.data);
      }
    })
  },
  methods: {
    Session(){
      if(!window.localStorage.getItem('token') && window.localStorage.getItem('token') != "null" && this.$route.name != 'login'){
      this.$store.commit("saveToken",window.localStorage.getItem('token'));
      this.$store.commit("saveUserId",window.localStorage.getItem('user_id'));
      window.location.href = window.location.origin+"/#/login";
      }else{
        this.$store.commit("saveToken",window.localStorage.getItem('token'));
        this.$store.commit("saveUserId",window.localStorage.getItem('user_id'));
        this.$http.defaults.headers.common['Authorization'] = this.$store.state.Session.token;
      }
      this.$http.interceptors.response.use(response => {
        return response;
      }, error => {
        if (error.response.status === 401) {
          this.$store.commit("saveToken",window.localStorage.getItem('token'));
          this.$store.commit("saveUserId",window.localStorage.getItem('user_id'));
          window.location.href = window.location.origin+"/#/login";
        }
        return error;
      });
    }
  },
};
</script>
<style type="text/css">
.main-panel{
  border-top:0px;
}
</style>